/* src/components/InstructionsPopup.css */

.instructions-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center;
    z-index: 1001;
    overflow: hidden; /* Prevent scrolling */
}

.instructions-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    width: 100%;
    font-family: 'Arial', sans-serif;
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    max-height: 90vh;
    overflow-y: auto; /* Enable scrolling within the popup if content overflows */
}

.game-title {
    font-size: 2rem;
    margin-bottom: 15px;
    font-family: 'Courier New', Courier, monospace;
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.game-logo {
    max-width: 150px;
    height: auto;
}

.instructions-content h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-family: 'Arial', sans-serif;
}

.instructions-content p {
    font-size: 1rem;
    margin-bottom: 15px;
    line-height: 1.5;
    color: #333;
    font-family: 'Arial', sans-serif;
}

.instructions-content .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #000; /* Ensure the close icon is visible */
}

.instructions-content .puzzle-info {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #555;
}

/* Styles for the game grid within the instructions */
.instructions-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
}

.instructions-grid .grid-row {
    display: flex;
}

.instructions-grid .grid-cell {
    width: 40px;
    height: 40px;
    border: 2px solid #000;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    background-color: #fff;
    font-family: 'Courier New', Courier, monospace;
    /* Remove gaps between cells */
    margin: 0;
}

.instructions-grid .grid-cell.correct {
    background-color: #1E90FF;
    color: #fff;
    border: none;
}

.instructions-grid .grid-cell.present {
    background-color: #FFA500;
    color: #fff;
    border: none;
}

.instructions-grid .grid-cell.absent {
    background-color: #787c7e;
    color: #fff;
    border: none;
}

/* Remove gaps between grid rows */
.instructions-grid .grid-row + .grid-row {
    margin-top: 0;
}

/* Responsive Design */

/* Tablet Portrait */
@media (min-width: 481px) and (max-width: 768px) {
    .instructions-content {
        padding: 15px;
    }

    .game-title {
        font-size: 1.8rem;
    }

    .instructions-content h2 {
        font-size: 1.3rem;
    }

    .instructions-content p {
        font-size: 0.9rem;
    }

    .game-logo {
        max-width: 120px;
    }

    .instructions-grid .grid-cell {
        width: 35px;
        height: 35px;
        font-size: 1.3rem;
    }
}

/* Mobile Phones Portrait */
@media (max-width: 480px) {
    .instructions-popup {
        align-items: flex-start; /* Align to top */
    }

    .instructions-content {
        padding: 10px;
        width: 100%; /* Edge to edge horizontally */
        border-radius: 0; /* Remove border radius on mobile */
        max-height: 100vh; /* Make it cover the full viewport height */
        height: 100vh;
        overflow-y: auto; /* Enable scrolling within the popup */
    }

    .game-title {
        font-size: 1.5rem;
    }

    .instructions-content h2 {
        font-size: 1.1rem;
    }

    .instructions-content p {
        font-size: 0.9rem;
    }

    .game-logo {
        max-width: 100px;
    }

    .instructions-grid .grid-cell {
        width: 30px;
        height: 30px;
        font-size: 1.1rem;
    }

    .instructions-content .close-button {
        font-size: 2rem;
    }
}





