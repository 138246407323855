/* src/components/GameBoard.css */

/* Reset default browser styles for consistency */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Body Styling */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    font-family: 'Courier New', Courier, monospace;
    background-color: #f0f0f0;
}

/* Game Board Container */
.game-board {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.625rem 0 3rem 0; /* 10px top, 0px sides, 48px bottom */
    background-color: #ffffff;
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
}

/* Main Heading */
h1 {
    font-size: 2rem;
    margin-bottom: 0.625rem; /* 10px */
    font-weight: bold;
    text-align: center;
}

/* Guesses Remaining Counter */
.guesses-remaining {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.3125rem; /* 5px */
    text-align: center;
}

/* Scrambled Words Section */
.scrambled-words {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem; /* 5px */
    margin-bottom: 0.3125rem; /* 5px */
    width: 100%;
    align-items: center;
}

/* Individual Scrambled Word Container */
.wordle-word {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 0.3125rem; /* 5px */
    width: 100%;
}

/* Word Container for Scrambled Word */
.word-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    padding-right: 0.3125rem; /* 5px */
    justify-content: center;
}

/* Scrambled Word Styling */
.scrambled-word {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    flex: 1;
}

/* Wordle Grid for Scrambled Words */
.wordle-grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 0;
}

/* Scrambled Letters Grid */
.scrambled-grid {
    display: flex;
    gap: 0;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

/* Container for Each Letter Box */
.wordle-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0;
    flex-shrink: 1;
}

/* Scrambled Letter Label */
.scrambled-letter {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 0.1875rem; /* 3px */
}

/* Letter Box Styling */
.wordle-box {
    color: black;
    width: 10vw;
    height: 10vw;
    max-width: 3.625rem; /* 58px */
    max-height: 3.625rem; /* 58px */
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
    border: 0.125rem solid #000;
    background-color: white;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    transition: background-color 0.3s, border-color 0.3s;
    flex-shrink: 1;
    border-radius: 0;
}

/* Correct Word Styling */
.wordle-box.correct {
    background-color: #1E90FF !important;
    color: white !important;
    border: none !important;
}

/* Disabled and Read-Only Correct Words */
.wordle-box.correct:disabled,
.wordle-box.correct[readonly] {
    background-color: #1E90FF !important;
    color: white !important;
    border: none !important;
}

/* Read-Only Incorrect Words */
.wordle-box[readonly]:not(.correct) {
    background-color: #d3d6da;
    color: #000;
    border: 0.0625rem solid #878a8c; /* 1px */
}

/* Disabled Incorrect Words */
.wordle-box:disabled:not(.correct) {
    background-color: #d3d6da;
    color: #000;
    border: 0.0625rem solid #878a8c; /* 1px */
}

/* Separator Line Between Sections */
.separator-line {
    width: 90%;
    border: 0.0625rem solid #ccc; /* 1px */
    margin: 0.3125rem 0; /* 5px top and bottom */
}

/* Theme Guess Section */
.theme-guess {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem; /* 24px */
    width: 100%;
}

/* Theme Header and Placeholder Container */
.theme-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 0.3125rem; /* 5px */
    position: relative;
    padding-right: 0.625rem; /* 10px */
    justify-content: center;
}

/* Theme Header Styling */
.theme-header {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    flex: 1;
}

/* Theme Grid Container */
.theme-grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.3125rem; /* 5px */
    width: 100%;
    overflow-x: hidden;
}

/* Theme Grid Styling */
.theme-grid {
    display: flex;
    gap: 0;
    justify-content: center;
    margin-bottom: 0.3125rem; /* 5px */
    flex-wrap: nowrap;
    overflow-x: auto;
}

/* Position container for each letter + small hints. */
.theme-grid-position {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

/* Small hint label for misplaced letters */
.misplaced-letter-hint {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    margin-top: 2px;
}

/* Each letter in the hint */
.misplaced-letter-span {
    font-size: 0.7rem;
    color: #FFA500;
    font-weight: bold;
    pointer-events: none;
    text-transform: uppercase;
}

/* Inputs in Theme Grid */
.theme-grid input {
    pointer-events: none;
    width: 10vw;
    height: 10vw;
    max-width: 3.625rem; /* 58px */
    max-height: 3.625rem; /* 58px */
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
    border: 0.125rem solid #000;
    background-color: white;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    transition: background-color 0.3s, border-color 0.3s;
    border-radius: 0;
}

/* Correct Theme Letters */
.theme-grid input.correct {
    background-color: #1E90FF !important;
    color: white !important;
    border: none !important;
}

/* User Input Styling */
.user-input {
    width: 80%;
    max-width: 18.75rem; /* 300px */
    padding: 0.625rem; /* 10px */
    font-size: 1.2rem;
    border: 0.125rem solid #000;
    border-radius: 0.3125rem; /* 5px */
    margin-bottom: 0.9375rem; /* 15px */
    text-align: center;
    background-color: #f9f9f9;
    z-index: 10;
}

/* Shake Animation */
@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-0.3125rem);
    }
    50% {
        transform: translateX(0.3125rem);
    }
    75% {
        transform: translateX(-0.3125rem);
    }
    100% {
        transform: translateX(0);
    }
}

.shake {
    animation: shake 0.3s;
}

/* Wave Animation for Correct Theme Guess */
@keyframes wave {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.2);
    }
    40% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}

.wordle-box.wave {
    animation: wave 0.6s ease-in-out forwards;
}

/* Congratulations and Game Over Popups */
.congrats-popup,
.gameover-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.congrats-content,
.gameover-content {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    width: 90%;
    max-width: 480px;
    margin: 0 auto;
    animation: fadeIn 0.5s ease-in-out;
    position: relative;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Title in end-game popups */
.end-title {
    margin-bottom: 0.5rem;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

/* Shared text styling inside popups */
.congrats-content p,
.gameover-content p {
    margin-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: normal;
    line-height: 1.4;
}

.close-button {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    /* We override color/background in inline style for the new request. */
}

/* Stats container for a left-to-right layout */
.stats-container {
    text-align: left;
    margin-bottom: 1rem;
}

.stats-row {
    display: flex;
    justify-content: space-between;
    margin: 0.3rem 0;
}

.stat-title {
    font-weight: bold;
}

.stat-value {
    margin-left: 1rem;
}

/* End-game popup button style - final color will be overridden inline if needed */
.congrats-content button,
.gameover-content button {
    margin-top: 1rem;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #6aaa64; /* overridden inline if needed */
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.congrats-content button:hover,
.gameover-content button:hover {
    opacity: 0.85;
}

.congrats-content button:active,
.gameover-content button:active {
    opacity: 0.75;
}

/* Duplicate Guess Error */
.error-overlay {
    position: absolute;
    top: 0.625rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #FFF;
    padding: 0.625rem 0.9375rem;
    border-radius: 0.3125rem;
    font-size: 1rem;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1000;
    pointer-events: none;
}

.error-overlay.visible {
    opacity: 0.9;
}

.error-overlay p {
    margin: 0;
    text-align: center;
}

/* Look at you go! and Good try messages */
.game-message-overlay {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #FFF;
    padding: 0.625rem 0.9375rem;
    border-radius: 0.3125rem;
    font-size: 1.2rem;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1000;
    pointer-events: none;
}

.game-message-overlay.visible {
    opacity: 0.9;
}

.game-message-overlay p {
    margin: 0;
    text-align: center;
}

/* Keyboard Styling */
.keyboard {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: calc(env(safe-area-inset-bottom) + 3rem);
}

/* Keyboard Row Styling */
.keyboard-row {
    display: flex;
    justify-content: center;
    margin-bottom: 0.3125rem;
    width: 100%;
    flex-wrap: nowrap;
}

/* Standard Keys */
.key {
    background-color: #e0e0e0;
    border: none;
    margin: 0.125rem;
    padding: 0;
    font-size: 1rem;
    border-radius: 0;
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
    width: 8vw;
    max-width: 2.6875rem;
    height: 8vw;
    max-height: 3.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    box-sizing: border-box;
}

/* Special Keys (Enter & Delete) */
.key.special-key {
    width: 12vw;
    max-width: 4.0625rem;
    height: 8vw;
    max-height: 3.625rem;
    font-size: 0.8rem !important;
    line-height: 3.625rem;
}

/* Delete Key Icon Styling */
.key.special-key .delete-icon {
    width: 1rem;
    height: 1rem;
    color: #000;
}

/* Grayed Out Keys for Absent Letters */
.key.absent {
    background-color: #787c7e;
    color: #ffffff;
    cursor: not-allowed;
}

/* Present Letters Styling */
.key.present {
    background-color: #FFA500;
    color: white;
}

/* Correct Letters Styling */
.key.correct {
    background-color: #1E90FF;
    color: white;
}

/* Active Scrambled Letters Styling */
.key.active-scrambled {
    background-color: #d3d3d3;
    color: black;
    cursor: pointer;
}

/* Inactive Scrambled Letters Styling */
.key.inactive-scrambled {
    background-color: #A9A9A9 !important;
    color: white !important;
    cursor: not-allowed;
}

/* Hover Effects */
@media (hover: hover) {
    .key:hover:not(.disabled):not(.absent):not(.present) {
        background-color: #c0c0c0;
    }
}

/* Active State Styling */
.key:active:not(.disabled):not(.absent):not(.present) {
    background-color: #c0c0c0;
}

/* Splash Screen */
.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1002;
}

.splash-content {
    background-color: white;
    padding: 1.875rem;
    border-radius: 0.625rem;
    text-align: center;
    font-family: 'Arial', sans-serif;
    width: 90%;
    max-width: 31.25rem;
    animation: fadeIn 0.5s ease-in-out;
    position: relative;
}

.splash-content h1 {
    font-size: 2.5rem;
    margin-bottom: 0.9375rem;
    font-family: 'Courier New', Courier, monospace;
}

.splash-content p {
    font-size: 1.2rem;
    margin-bottom: 0.625rem;
}

.splash-content button {
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    background-color: #007AFF;
    color: white;
    border: none;
    border-radius: 0.3125rem;
    cursor: pointer;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    transition: background-color 0.3s ease;
    display: block;
    margin: 0.625rem auto;
}

.splash-content button:hover {
    background-color: #005BB5;
}

/* Responsive Design Enhancements */

/* Mobile Phones Portrait */
@media (max-width: 480px) {
    .wordle-box {
        width: 12vw;
        height: 12vw;
        font-size: 1.5rem;
        border-radius: 0;
    }

    .theme-grid input {
        width: 12vw;
        height: 12vw;
        font-size: 1.5rem;
        border-radius: 0;
    }

    .key {
        width: 12vw;
        height: 12vw;
        font-size: 1.2rem;
        border-radius: 0;
    }

    .key.special-key {
        width: 14vw;
        height: 12vw;
        font-size: 1rem !important;
    }

    .scrambled-letter {
        font-size: 0.9rem;
        margin-bottom: 0.1875rem;
    }

    .congrats-content,
    .gameover-content,
    .splash-content {
        padding: 1.25rem;
        width: 100%;
        border-radius: 0;
    }

    .congrats-content h2,
    .gameover-content h2,
    .splash-content h1 {
        font-size: 1.8rem;
    }

    .congrats-content p,
    .gameover-content p,
    .splash-content p {
        font-size: 1rem;
    }

    .congrats-content button,
    .gameover-content button,
    .splash-content button {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }

    .keyboard {
        padding-bottom: calc(env(safe-area-inset-bottom) + 5rem);
    }

    .game-board {
        min-height: 100vh;
    }
}













































